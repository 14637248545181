<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <DialogAttachment 
      :dialog_attachment="dialog.attachment" 
      :state="process.state" 
      :company_id="form.id"
      @input="data_attachment" 
      @file_response="response_attachment" 
      @close="closeDialog"/>

    <div class="mt-5">
      <div class="title black--text mb-2">Update Company</div>
      <Breadcrumbs :items="breadcrumbs"/>
    </div>

    <v-card outlined class="rounded-lg mb-5" v-if="!process.run">
      <v-card-text>
        <v-form>

          <v-tabs
            v-model="step"
            grow
          >
            <v-tab
              v-for="item in steps"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-divider></v-divider>

          <v-tabs-items v-model="step" class="mt-4">
            <v-tab-item key="profile">
              <ValidationObserver ref="profileObserver">
                <v-card
                  color="basil"
                  flat>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <ValidationProvider ref="Name" name="name" rules="required" v-slot="{ errors }">
                          <v-text-field
                            outlined
                            dense
                            flat
                            v-model="form.name"
                            :error-messages="errors"
                            label="Name"
                            color="#F05326">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <ValidationProvider ref="Name" name="Phone Number" rules="required|min:10|max:15" v-slot="{ errors }">
                          <v-text-field
                            outlined
                            dense
                            flat
                            v-model="form.phone_number"
                            :error-messages="errors"
                            label="Phone Number"
                            color="#F05326">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <ValidationProvider ref="Name" name="Email" rules="required|email" v-slot="{ errors }">
                          <v-text-field
                            outlined
                            dense
                            flat
                            v-model="form.email"
                            :error-messages="errors"
                            type="email"
                            label="Email"
                            color="#F05326">
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <ValidationProvider ref="Name" name="Description" rules="required" v-slot="{ errors }">
                          <v-textarea
                            outlined
                            dense
                            flat
                            v-model="form.description"
                            :error-messages="errors"
                            label="Description"
                            rows="3"
                            color="#F05326">
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <ValidationProvider ref="Name" name="Short Description" rules="required" v-slot="{ errors }">
                          <v-textarea
                            outlined
                            dense
                            flat
                            v-model="form.short_description"
                            :error-messages="errors"
                            label="Short Description"
                            rows="3"
                            color="#F05326">
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
  
                    <v-divider></v-divider>
                    <p class="text-h6">Company Address</p>
                    
                    <div v-if="form.addresses != null && form.addresses.length > 0">
                      <v-row>
                        <v-col cols="6" v-for="(address, i) in form.addresses || []" :key="i">
                          <v-card outlined class="mb-3">
                            <v-card-title>
                              <span class="font-weight-bold">Address {{ i + 1 }}</span>
                              <v-btn
                                small
                                depressed
                                color="error"
                                class="ml-2"
                                @click="deleteAddress(i)">
                                Hapus
                              </v-btn>
                            </v-card-title>
                            <v-card-text class="py-0">
                              <v-row no-gutters>
                                <v-col cols="12">
                                  <v-text-field
                                    outlined
                                    dense
                                    flat
                                    v-model="address.title"
                                    label="Title. Ex: Kantor Yogyakarta"
                                    color="#F05326">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    outlined
                                    dense
                                    flat
                                    rows="2"
                                    v-model="address.address"
                                    label="Address"
                                    color="#F05326">
                                  </v-textarea>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
  
                    <v-btn 
                      small
                      depressed
                      color="primary"
                      class="my-3 text-capitalize"
                      @click="addAddress">
                      <v-icon left>mdi-plus</v-icon>
                      Add Address
                    </v-btn>
  
                    <v-divider></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <p class="body-1 font-weight-bold">Upload Logo</p>
                        <div :class="['dropZone', 'cursor-pointer']" @click="dialog.attachment = true; process.state = 'logo'">
                          <div v-if="!filepreviewlogo" class="dropZone-info">
                            <span class="fa fa-cloud-upload dropZone-title"></span>
                            <div class="dropZone-upload-limit-info">
                              <div class="text-center mt-1">
                                <v-btn width="150" depressed dense>
                                  Browse File
                                </v-btn>
                              </div>
                            </div>
                          </div>
                          <div v-else class="dropZone-info" @click="dialog.attachment = true; process.state = 'logo'">
                            <span class="fa fa-cloud-upload dropZone-title"></span>
                            <v-img
                              aspect-ratio="7"
                              contain
                              class="mx-auto"
                              :src="filepreviewlogo">
                            </v-img>
                          </div>
                        </div>
                      </v-col>
  
                      <v-col cols="12" class="mt-7">
                        <p class="body-1 font-weight-bold">Upload Favicon</p>
                        <div 
                          :class="[
                            'dropZone',
                            dragging ? 'dropZone-over' : 'cursor-pointer',
                          ]"
                          @dragenter="dragging = true"
                          @dragleave="dragging = false">
                          <div v-if="!filepreviewfavicon" class="dropZone-info" @drag="onChange">
                            <span class="fa fa-cloud-upload dropZone-title"></span>
                            <div v-if="process.upload" class="px-16">
                              <v-progress-linear
                                indeterminate
                                color="primary">
                              </v-progress-linear>
                              <span>
                                Uploading File . . .
                              </span>
                            </div>
                            <div v-if="!process.upload" class="dropZone-upload-limit-info">
                              <div class="text-center mt-1">
                                <v-btn width="150" depressed dense>
                                  Browse File
                                </v-btn>
                              </div>
                            </div>
                          </div>
                          <div v-else class="dropZone-info">
                            <span class="fa fa-cloud-upload dropZone-title"></span>
                            <div v-if="process.upload">
                              <v-progress-linear
                                indeterminate
                                color="primary">
                              </v-progress-linear>
                              <span>
                                Uploading File . . .
                              </span>
                            </div>
                            <v-img
                              v-if="!process.upload"
                              aspect-ratio="7"
                              contain
                              class="mx-auto"
                              :src="filepreviewfavicon">
                            </v-img>
                          </div>
                          <form ref="fileUploaded">
                            <input
                              accept=".ico"
                              ref="inputFile"
                              type="file"
                              @change="onChange"
                            />
                          </form>
                        </div>
                      </v-col>
                    </v-row>
  
                  </v-card-text>
                  <v-card-actions class="px-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      width="120"
                      height="40"
                      color="grey lighten-3"
                      class="text-capitalize"
                      to="/company"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      depressed
                      width="120"
                      height="40"
                      color="primary"
                      class="text-capitalize"
                      @click="saveProfile()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </ValidationObserver>
            </v-tab-item>
            <v-tab-item key="setting">

              <section class="mt-4">
                <v-divider></v-divider>
                <p class="text-h6">Appearance</p>
                <v-text-field
                  placeholder="Ex: F05326"
                  label="Website Theme"
                  class="my-3"
                  hide-details
                  outlined
                  dense
                  flat
                  color="#F05326"
                  v-model="form.setting.appearance.theme_color">
                </v-text-field>
              </section>

              <section class="mt-4">
                <v-divider></v-divider>
                <p class="text-h6">Company Configuration</p>
                <v-row class="">
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> Enable Notifications</span>
                        <v-switch
                          v-model="form.setting.enable_notification"
                          flat
                          :label="form.setting.enable_notification ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> Enable OTP Registration</span>
                        <v-switch
                          v-model="form.setting.enable_register_otp"
                          flat
                          :label="form.setting.enable_register_otp ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> Enable User Profile Update <br/>(one time)</span>
                        <v-switch
                          v-model="form.setting.one_time_update_profile"
                          flat
                          :label="form.setting.one_time_update_profile ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> Enable Member Redeem Data Synchronization</span>
                        <v-switch
                          v-model="form.setting.enable_sync_data_member_redeem"
                          flat
                          :label="form.setting.enable_sync_data_member_redeem ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col></v-col>
                  <v-col></v-col>
                </v-row>
              </section>
              
              <section class="mt-4">
                <v-divider></v-divider>
                <p class="text-h6">License Configuration</p>
                
                <v-row>
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> License Prefix</span>
                        <v-switch
                          v-model="form.setting.license.prefix.enable"
                          flat
                          :label="form.setting.license.prefix.enable ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-expand-transition>
                      <v-card outlined class="px-4" v-if="form.setting.license.prefix.enable == true">
                        <div class="d-flex-col justify-center align-center">
                          <v-text-field
                            placeholder="Please Input License Prefix"
                            class="my-3"
                            hide-details
                            outlined
                            dense
                            flat
                            color="#F05326"
                            v-model="form.setting.license.prefix.value">
                          </v-text-field>
                        </div>
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row>

                <v-row class="mb-4">
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> License Surfix</span>
                        <v-switch
                          v-model="form.setting.license.surfix.enable"
                          flat
                          :label="form.setting.license.surfix.enable ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-expand-transition>
                      <v-card outlined class="px-4" v-show="form.setting.license.surfix.enable == true">
                        <div class="d-flex-col justify-center align-center">
                          <v-text-field
                            placeholder="Please Input License Surfix"
                            class="my-3"
                            hide-details
                            outlined
                            dense
                            flat
                            color="#F05326"
                            v-model="form.setting.license.surfix.value">
                          </v-text-field>
                        </div>
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </section>

              <section class="mt-4">
                <v-divider></v-divider>
                <p class="text-h6">Prakerja Configuration</p>
                <v-row>
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> Using Prakerja</span>
                        <v-switch
                          v-model="form.setting.sync_redemption_prakerja"
                          flat
                          :label="form.setting.sync_redemption_prakerja ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-expand-transition>
                  <div v-show="form.setting.sync_redemption_prakerja">
                    <v-row>
                      <v-col>
                        <v-text-field
                          outlined
                          dense
                          flat
                          v-model="form.setting.prakerja.callback_url"
                          label="Callback Prakerja URL"
                          placeholder="Please input Callback Prakerja URL"
                          rows="3"
                          color="#F05326">
                        </v-text-field>
                      </v-col>
                    </v-row>
    
                    <v-row class="pt-0 mt-0">
                      <v-col>
                        <v-text-field
                          outlined
                          dense
                          flat
                          v-model="form.setting.prakerja.credential.client_code"
                          label="Prakerja Client Code"
                          placeholder="Please input Prakerja Client Code"
                          color="#F05326">
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          outlined
                          dense
                          flat
                          v-model="form.setting.prakerja.credential.secret_key"
                          label="Prakerja Secret Key"
                          placeholder="Please input Prakerja Secret Key"
                          color="#F05326">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>

              </section>

              <v-card-actions class="px-4">
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="grey lighten-3"
                  class="text-capitalize"
                  to="/company"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="primary"
                  class="text-capitalize"
                  @click="saveSettings()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item>
              <ValidationObserver ref="certificateObserver">
                <v-row class="mb-4">
                  <v-col>
                    <v-card outlined class="px-4">
                      <div class="d-flex justify-space-between align-center">
                        <span> Competency Field Shown</span>
                        <v-switch
                          v-model="form.certificate.show_competence"
                          flat
                          :label="form.certificate.show_competence ? 'Yes' : 'No'">
                        </v-switch>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col>
                    <ValidationProvider name="Participant Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.certificate.participant.title"
                        :error-messages="errors"
                        label="Participant Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Participant Sub Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.certificate.participant.sub_title"
                        :error-messages="errors"
                        label="Participant Sub Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Graduation Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.certificate.graduation.title"
                        :error-messages="errors"
                        label="Graduation Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Graduation Sub Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.certificate.graduation.sub_title"
                        :error-messages="errors"
                        label="Graduation Sub Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col>
                    <ValidationProvider name="Signature Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.certificate.signature.title"
                        :error-messages="errors"
                        label="Signature Title"
                        placeholder="ex : Direktur"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider name="Signature Name" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.certificate.signature.name"
                        :error-messages="errors"
                        label="Signature Name"
                        placeholder="ex : John Doe"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
  
                <v-row no-gutters>
                  <v-col cols="12">
                    <p class="body-1 font-weight-bold">Upload Signature</p>
  
                    <div :class="['dropZone', 'cursor-pointer']" @click="dialog.attachment = true; process.state = 'signature'">
                      <div v-if="!filepreviewsignature" class="dropZone-info">
                        <span class="fa fa-cloud-upload dropZone-title"></span>
                        <div class="dropZone-upload-limit-info">
                          <div class="text-center mt-1">
                            <v-btn width="150" depressed dense>
                              Browse File
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div v-else class="dropZone-info" @click="dialog.attachment = true; process.state = 'signature'">
                        <span class="fa fa-cloud-upload dropZone-title"></span>
                        <v-img
                          aspect-ratio="7"
                          contain
                          class="mx-auto"
                          :src="filepreviewsignature">
                        </v-img>
                      </div>
                    </div>
                  </v-col>
                </v-row>
  
                <v-card-actions class="px-4">
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    width="120"
                    height="40"
                    color="grey lighten-3"
                    class="text-capitalize"
                    to="/company"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    width="120"
                    height="40"
                    color="primary"
                    class="text-capitalize"
                    @click="saveSettingsCertificate()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-tab-item>
            <v-tab-item>
              <v-tabs v-model="tab" class="ml-4">
                <v-tab
                  active-class="active-tab"
                  v-for="item in ['Whatsapp', 'Inbox']"
                  :key="item"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-tabs-items v-model="tab">
                <v-tab-item class="ml-3">
                  <v-row class="mt-2">
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        dense
                        flat
                        v-model="form.setting.notification_template.whatsapp.registration"
                        label="Registration Whatsapp Template"
                        placeholder="Please input registration whatsapp template"
                        rows="8"
                        color="#F05326">
                      </v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        dense
                        flat
                        v-model="form.setting.notification_template.whatsapp.request_change_password"
                        label="Request Change Password Whatsapp Template"
                        placeholder="Please input request change password whatsapp template"
                        rows="8"
                        color="#F05326">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="ml-3">
                  <v-textarea
                    outlined
                    dense
                    flat
                    v-model="form.setting.notification_template.inbox.program_completed"
                    label="Program Completed Inbox Template"
                    placeholder="Please input program completed inbox template"
                    rows="8"
                    class="mt-5"
                    color="#F05326">
                  </v-textarea>
                </v-tab-item>
              </v-tabs-items>

              <v-card-actions class="px-4">
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="grey lighten-3"
                  class="text-capitalize"
                  to="/company"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="primary"
                  class="text-capitalize"
                  @click="saveNotificationTemplate()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item>
              <v-row class="mb-3">
                <v-col cols="12" v-for="(slide, i) in form.sliders" :key="i">
                  <v-card outlined class="pa-4">
                    <v-card-title>
                      <span class="font-weight-bold">Slide {{ i + 1 }}</span>
                      <v-btn
                        small
                        depressed
                        color="error"
                        class="ml-2"
                        @click="deleteSlider(i)">
                        Hapus
                      </v-btn>
                    </v-card-title>
                    <v-text-field
                    outlined
                    dense
                    flat
                    color="primary"
                    label="Link Slide"
                    placeholder="Please Input Link Slide"
                    v-model="slide.link">
                    </v-text-field>
                    <!-- Upload imgae -->
                    <div :class="['dropZone', 'cursor-pointer']" @click="dialog.attachment = true; process.state = `slide-${i}`">
                      <div v-if="!slide.filepreview" class="dropZone-info">
                        <span class="fa fa-cloud-upload dropZone-title"></span>
                        <div class="dropZone-upload-limit-info">
                          <div class="text-center mt-1">
                            <v-btn width="150" depressed dense @click="dialog.attachment = true; process.state = `slide-${i}`">
                              Browse File
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div v-else class="dropZone-info">
                        <span class="fa fa-cloud-upload dropZone-title"></span>
                        <v-img
                          aspect-ratio="5"
                          contain
                          class="mx-auto"
                          :src="slide.filepreview">
                        </v-img>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  
                  depressed
                  height="40"
                  color="primary"
                  class="text-capitalize mb-3" 
                  @click="addSlider">
                  <v-icon left>mdi-plus</v-icon>
                  Add Slider
                </v-btn>
              </v-card-actions>

              <v-card-actions class="px-4">
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="grey lighten-3"
                  class="text-capitalize"
                  to="/company"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="primary"
                  class="text-capitalize"
                  @click="saveHomeSliders()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item>
              <v-row class="mb-3">
                <v-col cols="4" v-for="(contact, i) in Object.keys(form.contact)" :key="i">
                  <v-card outlined class="pa-4">
                    <p class="text-h6 text-capitalize">{{ contact }}</p>
                    <v-text-field
                    outlined
                    dense
                    flat
                    color="primary"
                    :hint="`Ex: ${form.contact[contact].example}`"
                    persistent-hint
                    v-model="form.contact[contact].link"
                    :label="`Link ${contact}`"
                    :placeholder="`Please Input Link ${contact}`">
                    </v-text-field>
                  </v-card>
                </v-col>
              </v-row>

              <v-card-actions class="px-4">
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="grey lighten-3"
                  class="text-capitalize"
                  to="/company"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  width="120"
                  height="40"
                  color="primary"
                  class="text-capitalize"
                  @click="saveContacts()"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { get, post, put , upload } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import DialogAttachment from './DialogAttacment.vue'
export default {
  components: { Breadcrumbs, Snackbar, DialogAttachment },
  data() {
    return {
      step: null,
      tab: null,
      steps: [
        'Profile',
        'Setting',
        'Setting Certificate',
        'Notification Template',
        'Home Slider',
        'Contacts'
      ],
      breadcrumbs: [
        {
          text: 'List Company',
          disabled: false,
          href: '/company',
        },
        {
          text: 'Update Company',
          disabled: true,
          href: '#',
        }
      ],
      items:{},
      member: {},
      form: {
        setting: {
          appearance: {
            theme_color: ""
          },
        },
        contact: {
          email: {
            link: '',
            example: 'example@gmail.com'
          },
          facebook: {
            link: '',
            example: 'https://www.facebook.com/facebookname'
          },
          whatsapp: {
            link: '',
            example: 'https://wa.me/6281234567890'
          },
          instagram: {
            link: '',
            example: 'https://www.instagram.com/instagramname'
          },
          telegram: {
            link: '',
            example: 'https://t.me/telegramname'
          },
        },
        favicon: "",
      },
      dialog: {
        attachment: false,
      },
      // Upload logo
      dragging: false,
      filefavicon: [],
      filepreviewfavicon: '',
      filepreviewlogo: '',
      fileUploadlogo: [],
      // upload signature
      // dragging: false,
      filesignature: [],
      filepreviewsignature: '',
      fileUploadsignature: [],
      process: {
        upload: false,
        run: false,
        state: ''
      },
    }
  },
  created() {
    this.getDetail();
  },
  mounted() {
  },
  methods:{
    addSlider() {
      this.form.sliders.push({
        link: "",
        slider_id: "",
        filepreview: ""
      })
    },
    deleteSlider(index) {
      this.form.sliders.splice(index, 1)
    },
    addAddress() {
      this.form.addresses.push({
        title: "",
        address: "",
      })
    },
    deleteAddress(index) {
      this.form.addresses.splice(index, 1)
    },
    async getDetail() {
      let _self = this;
      _self.process.run = true

      await get(`company/v1/superuser/detail/${this.$route.params.id}`).then(response => {
        let res = response.data

        Object.keys(res.data.contact).forEach((item, i) => {
          res.data.contact[item].example = this.form.contact[item].example
          this.form.contact[item].link = res.data.contact[item].link
          // this.form.contact[item].example = res.data.contact[item].example
        })

        this.form = res.data
        // console.log(res.data.favicon.url);
        this.form.setting.appearance = res.data.setting.appearance != null ? res.data.setting.appearance : { theme_color: "" }
        this.form.sliders = res.data.sliders != null ? res.data.sliders : []
        this.form.addresses = res.data.addresses != null ? res.data.addresses : []
        this.form.logo = res.data.certificate.logo.id
        this.filepreviewlogo = res.data.certificate.logo.url
        this.filepreviewsignature = res.data.certificate.signature.image.url
        this.form.certificate.signature.image = res.data.certificate.signature.image.id
        this.filepreviewfavicon = res.data.favicon != null ? res.data.favicon.url : ""
        this.form.favicon = res.data.favicon != null ? res.data.favicon.id : ""

        res.data.sliders.forEach((item, i) => {
          this.form.sliders[i].filepreview = item.data.url
          this.form.sliders[i].slider_id = item.data.id
          this.form.sliders[i].link = item.link
        })


        this.process.run = false
        this.$store.state.process.run = false
      })
    },
    async saveProfile() {
      let isValid = this.$refs.profileObserver.validate()
      if(isValid && this.validation().profile()) {
        let data = {
          name: this.form.name,
          description: this.form.description,
          email: this.form.email,
          phone_number:this.form.phone_number,
          logo: this.form.logo ? this.form.logo : "",
          favicon: this.form.favicon ? this.form.favicon : "",
          addresses: this.form.addresses,
          short_description: this.form.short_description
        }

        this.process.run = true
        await put(`company/v1/superuser/update-profile/${this.$route.params.id}`,{
          data : data
        }).then(response => {
          let res = response.data
          if (res.status) {
            this.$refs.snackbar.open("#4CAF50", `Successfully Changed Company Profile`);
            setTimeout(() => {
              this.$router.push('/company')
            },1000);
          } else {
            this.$refs.snackbar.open("#D32F2F", `Failed to Change Company Profile`);
          }
          this.process.run = false
        }).catch(() => {
          this.process.run = false
        })
      }
    },
    async saveSettings() {
      let data = {
        appearance: this.form.setting.appearance,
        enable_notification: this.form.setting.enable_notification,
        enable_register_otp: this.form.setting.enable_register_otp,
        one_time_update_profile: this.form.setting.one_time_update_profile,
        enable_sync_data_member_redeem: this.form.setting.enable_sync_data_member_redeem,
        license: {
          prefix: {
            enable: this.form.setting.license.prefix.enable,
            value: this.form.setting.license.prefix.value
          },
          surfix: {
            enable: this.form.setting.license.surfix.enable,
            value: this.form.setting.license.surfix.value
          }
        },
        sync_redemption_prakerja: this.form.setting.sync_redemption_prakerja,
        prakerja: {
          callback_url: this.form.setting.prakerja.callback_url,
          credential: {
            client_code: this.form.setting.prakerja.credential.client_code,
            secret_key: this.form.setting.prakerja.credential.secret_key
          }
        }
      }

      this.process.run = true
      await put(`company/v1/superuser/update-setting/${this.$route.params.id}`,{
        data : data
      }).then(response => {
        let res = response.data
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Changed Company Setting`);
          setTimeout(() => {
            this.$router.push('/company')
          },1000);
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Change Company Setting`);
        }
        this.process.run = false
      }).catch(() => {
        this.process.run = false
      })
    },
    async saveSettingsCertificate() {
      let isValid = this.$refs.certificateObserver.validate()

      if(isValid && this.validation().settingCertificate()) {
        let data = {
          show_competence: this.form.certificate.show_competence,
          participant: {
            title: this.form.certificate.participant.title,
            sub_title: this.form.certificate.participant.sub_title
          },
          graduation: {
            title: this.form.certificate.graduation.title,
            sub_title: this.form.certificate.graduation.sub_title
          },
          signature: {
            title: this.form.certificate.signature.title,
            image: this.form.certificate.signature.image ? this.form.certificate.signature.image : "",
            name: this.form.certificate.signature.name
          }
        }
  
        this.process.run = true
        await put(`company/v1/superuser/update-setting-certificate/${this.$route.params.id}`,{
          data : data
        }).then(response => {
          let res = response.data
          if (res.status) {
            this.$refs.snackbar.open("#4CAF50", `Successfully Changed Company Certificate Setting`);
            setTimeout(() => {
              this.$router.push('/company')
            },1000);
          } else {
            this.$refs.snackbar.open("#D32F2F", `Failed to Change Company Certificate Setting`);
          }
          this.process.run = false
        }).catch(() => {
          this.process.run = false
        })
      }
    },
    async saveNotificationTemplate() {
      let data = {
        notification_template: {
          whatsapp: {
            registration: this.form.setting.notification_template.whatsapp.registration,
            request_change_password: this.form.setting.notification_template.whatsapp.request_change_password
          },
          inbox: {
            program_completed: this.form.setting.notification_template.inbox.program_completed
          }
        }
      }

      this.process.run = true
      await put(`company/v1/superuser/update-setting-notification-template/${this.$route.params.id}`,{
        data : data
      }).then(response => {
        let res = response.data
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Changed Company Notification Template`);
          setTimeout(() => {
            this.$router.push('/company')
          },1000);
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Change Company Notification Template`);
        }
        this.process.run = false
      }).catch(() => {
        this.process.run = false
      })
    },
    async saveHomeSliders() {
      if (this.validation().slider()) {
        let data = {
          sliders: this.form.sliders
        }
  
        this.process.run = true
        await put(`company/v1/superuser/update-sliders/${this.$route.params.id}`,{
          data : data
        }).then(response => {
          let res = response.data
          if (res.status) {
            this.$refs.snackbar.open("#4CAF50", `Successfully Changed Company Home Slider`);
            setTimeout(() => {
              this.$router.push('/company')
            },1000);
          } else {
            this.$refs.snackbar.open("#D32F2F", `Failed to Change Company Home Slider`);
          }
          this.process.run = false
        }).catch(() => {
          this.process.run = false
        })
      }
    },
    async saveContacts() {
      let data = {
        contact: this.form.contact
      }

      this.process.run = true
      await put(`company/v1/superuser/update-contact/${this.$route.params.id}`,{
        data : data
      }).then(response => {
        let res = response.data
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Changed Company Contacts`);
          setTimeout(() => {
            this.$router.push('/company')
          },1000);
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Change Company Contacts`);
        }
        this.process.run = false
      }).catch(() => {
        this.process.run = false
      })
    },

    validation() {
      let _self = this
      return {
        profile() {
          if (_self.form.name == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the company name`);
            return false
          }

          if (_self.form.description == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the company description`);
            return false
          }

          if (_self.form.email == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the company email`);
            return false
          }

          if (_self.form.phone_number == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the company phone number`);
            return false
          }

          // addresses validation

          if(_self.form.addresses.length == 0) {
            _self.$refs.snackbar.open("#D32F2F", `Please add at least one address`);
            return false
          }

          for (let i = 0; i < _self.form.addresses.length; i++) {
            if (_self.form.addresses[i].title == "") {
              _self.$refs.snackbar.open("#D32F2F", `Please fill in the address title`);
              return false
            }

            if (_self.form.addresses[i].address == "") {
              _self.$refs.snackbar.open("#D32F2F", `Please fill in the address`);
              return false
            }
          }

          return true
        },
        settingCertificate() {
          if (_self.form.certificate.signature.image == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the signature image`);
            return false
          }


          if(_self.form.certificate.participant.title == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the participant title`);
            return false
          }

          if(_self.form.certificate.participant.sub_title == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the participant sub title`);
            return false
          }

          if(_self.form.certificate.graduation.title == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the graduation title`);
            return false
          }

          if(_self.form.certificate.graduation.sub_title == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the graduation sub title`);
            return false
          }

          if(_self.form.certificate.signature.title == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the signature title`);
            return false
          }

          if(_self.form.certificate.signature.name == "") {
            _self.$refs.snackbar.open("#D32F2F", `Please fill in the signature name`);
            return false
          }

          return true
        },
        slider() {
          if (_self.form.sliders.length == 0) {
            _self.$refs.snackbar.open("#D32F2F", `Please add at least one slider`);
            return false
          }

          for (let i = 0; i < _self.form.sliders.length; i++) {
            if (_self.form.sliders[i].slider_id == "") {
              _self.$refs.snackbar.open("#D32F2F", `Please fill in the image slider`);
              return false
            }
          }

          return true
        }
      }
    },
    onChange(e) {
      this.process.upload = true
      let src = {
        url: null,
        title: null,
        alt: null,
        description: null,
        caption: null,
      }
      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0];

      if (this.draging) {
        this.draging = false;
        this.filepreview = URL.createObjectURL(selectedFiles);
        src.url = URL.createObjectURL(selectedFiles);
        src.title = selectedFiles.name;
        src.alt = selectedFiles.name;
        src.file = selectedFiles;
      } else {
        this.filepreview = URL.createObjectURL(e.target.files[0]);
        src.url = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
        src.file = e.target.files[0];
      }

      src.source = "upload";
      src.type = "image";

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.fileUpload = files;
      setTimeout(() => {
        // console.log(this.fileUpload);
        // this.process.upload = false
        this.setAttachment(src);
      }, 2000)
    },
    async setAttachment(file) {
      this.process.upload = true
      
      // FIXME: Masih bisa upload berbagai macam file
      let formData = new FormData()
      formData.append('source',file.source)
      formData.append('alt',file.alt)
      formData.append('caption', file.caption)
      formData.append('description', file.description)
      formData.append('file', file.file)
      formData.append('title',`${file.title}`)
      formData.append('url', file.url)

      await upload(`storage/v1/superuser/upload?company_id=${this.form.id}`, {
        data: formData
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          // file = { with_media: true, _id: res.data.id, media: res.data, status: this.state };
          // this.saving = false;
          this.form.favicon = res.data.id
          this.filepreviewfavicon = res.data.public_url
          this.filefavicon = file
          this.process.upload = false
        }
      }).catch((error) => {
        console.log(error);
        this.process.upload = false
        this.$refs.inputFile.value = null;
      });
    },

    // save() {
    //   let data = {
    //       name: this.form.name,
    //       description: this.form.description,
    //       email: this.form.email,
    //       image: "",
    //       phone_number:this.form.phone_number,
    //       additional_fields: {
    //         member_redeems: this.form.additional_fields.member_redeems ? this.form.additional_fields.member_redeems : [],
    //         members: this.form.additional_fields.members ? this.form.additional_fields.members : [],
    //         resellers: this.form.additional_fields.resellers ? this.form.additional_fields.resellers : [],
    //       },
    //       setting: {
    //         appearance: this.form.setting.appearance,
    //         enable_notification: this.form.setting.enable_notification,
    //         enable_register_otp: this.form.setting.enable_register_otp,
    //         one_time_update_profile: this.form.setting.one_time_update_profile,
    //         enable_sync_data_member_redeem: this.form.setting.enable_sync_data_member_redeem,
    //         export: {
    //           member_redeem: this.form.setting.export.member_redeem ? this.form.setting.export.member_redeem : [],
    //           member_redeem_quiz: this.form.setting.export.member_redeem_quiz ? this.form.setting.export.member_redeem_quiz : [],
    //           list_user_reseller: this.form.setting.export.list_user_reseller ? this.form.setting.export.list_user_reseller : [],
    //           presence: this.form.setting.export.presence ? this.form.setting.export.presence : [],
    //         },
    //         license: {
    //           prefix: {
    //             enable: this.form.setting.license.prefix.enable,
    //             value: this.form.setting.license.prefix.value
    //           },
    //           surfix: {
    //             enable: this.form.setting.license.surfix.enable,
    //             value: this.form.setting.license.surfix.value
    //           }
    //         },
    //         sync_redemption_prakerja: this.form.setting.sync_redemption_prakerja,
    //         prakerja: {
    //           callback_url: this.form.setting.prakerja.callback_url,
    //           credential: {
    //             client_code: this.form.setting.prakerja.credential.client_code,
    //             secret_key: this.form.setting.prakerja.credential.secret_key
    //           }
    //         },
    //       },
    //       logo: this.form.logo,
    //       show_competence: this.form.certificate.show_competence,
    //       participant: {
    //           title: this.form.certificate.participant.title,
    //           sub_title: this.form.certificate.participant.sub_title
    //       },
    //       graduation: {
    //           title: this.form.certificate.graduation.title,
    //           sub_title: this.form.certificate.graduation.sub_title
    //       },
    //       signature: {
    //           title: this.form.certificate.signature.title,
    //           image: this.form.certificate.signature.image,
    //           name: this.form.certificate.signature.name
    //       }
    //   }
    //   this.process.run = true
    //   put(`company/v1/superuser/update/${this.$route.params.id}`,{
    //     data : data
    //   }).then(response => {
    //     let res = response.data
    //     if (res.status) {
    //       this.$refs.snackbar.open("#4CAF50", `Berhasil Merubah Data Company`);
    //       setTimeout(() => {
    //         this.$router.push('/company')
    //         },2000);
    //     } else {
    //       this.$refs.snackbar.open("#D32F2F", `Gagal Merubah Data Company`);
    //     }
    //     this.process.run = false
    //   }).catch(() => {
    //     this.process.run = false
    //   })
    // },
    data_attachment(data){
      console.log(data);
    },
    response_attachment(res) {
      // console.log(res);

      // console.log('res',res);

      let status = res.status.split('-')[0]
      let index = res.status.split('-')[1]

      // console.log('status',status);
      // console.log('index',index);

      if(status == "logo") {
        this.form.logo = res._id
        this.filepreviewlogo = res.media.public_url
      } 
      
      if(status == "signature") {
        this.form.certificate.signature.image = res._id
        this.filepreviewsignature = res.media.public_url
      }

      if(status == "slide") {
        this.form.sliders[index].slider_id = res._id
        this.form.sliders[index].filepreview = res.media.public_url
      }

      // if (this.process.state == 'logo') {
      //   this.form.logo = res.data.id
      //   this.filepreviewlogo = res.media.public_url
      // } else if (this.process.state == 'signature') {
      //   this.form.certificate.signature.image = res.data.id
      //   this.filepreviewsignature = res.media.public_url
      // } else {
      //   this.form.sliders[this.process.state.split('-')[1]].slider_id = res.data.id
      //   this.form.sliders[this.process.state.split('-')[1]].filepreview = res.media.public_url
      // }
    },
    closeDialog () {
      this.dialog.attachment = false
    },
  }
}
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 300px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #F05326;
}

.dropZone:hover .dropZone-title {
  color: #F05326;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #ddd;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}

.active-tab {
  color: white !important;
  background-color: #F05326 !important;
  border-radius:  10px 10px 0 0;
  transition: ease .1s;
}
</style>